import Spacer from "components/spacer"
import useProductImage from "custom-hooks/useProductImage"
import Image from "next/image"
import React from "react"
import styled from "styled-components"
import { colors } from "styles/colors"
import { getPublicUrl, sluggedName, toCurrency } from "../../lib/utils"
import { motion } from "framer-motion"
import { useRouter } from "next/router"

const ProductItem = ({ item, onClick, index = 0 }) => {
  const {
    companyAcceptsCash,
    companyAcceptsPayouts,
    companyCategories,
    categoryName,
    companyName,
    image,
    onSale,
    price,
    salePrice,
    title,
  } = item
  const router = useRouter()
  const storeSlug = sluggedName(companyName)
  const placeholderImage = useProductImage("H9ToSaLhx7liC21nrXnW")

  return (
    <Container
      as={motion.div}
      layout={true}
      onClick={onClick}
      key={item.id}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        delay: 0,
        layout: {
          duration: 0.3,
          ease: [0.22, 1, 0.36, 1],
        },
      }}
      exit={{ opacity: 0 }}
    >
      <ImageContainer>
        <Image
          src={image ? getPublicUrl(image) : placeholderImage}
          alt={title}
          objectFit="cover"
          layout="fill"
        />
        {onSale ? <SaleLabel>Sale!</SaleLabel> : null}
      </ImageContainer>
      <TextBox>
        <Title>{title}</Title>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Price onSale={onSale ? true : undefined}>{toCurrency(price)}</Price>
          {onSale ? <SalePrice>{toCurrency(salePrice)}</SalePrice> : null}
        </div>
        <Spacer size={3} />
        {router?.asPath?.includes(storeSlug) ? (
          []
        ) : (
          <CompanyName>{companyName}</CompanyName>
        )}
        {categoryName && <CompanyName>{categoryName}</CompanyName>}
        <Description>
          {companyAcceptsPayouts
            ? "Pay online"
            : companyAcceptsCash && "Accepts cash"}
        </Description>
      </TextBox>
    </Container>
  )
}

export default ProductItem

const Container = styled.div`
  color: #21262e;
  cursor: pointer;
  position: relative;
`

const ImageContainer = styled.div`
  width: 100%;
  height: 280px;
  object-fit: cover;
  object-position: center;
  background-color: #eee;
  position: relative;

  @media (max-width: 1220px) {
    height: 350px;
  }

  @media (max-width: 980px) {
    height: 300px;
  }

  @media (max-width: 940px) {
    height: 380px;
  }

  @media (max-width: 550px) {
    height: 160px;
  }
`

const SaleLabel = styled.div`
  position: absolute;
  width: 60px;
  font-size: 18px;
  padding: 6px 10px;
  top: -10px;
  right: -10px;
  background-color: ${colors.sale};
  color: #fff;
  text-align: center;
  font-weight: 400;
  font-family: "PhantomSans";
  text-transform: uppercase;

  @media (max-width: 550px) {
    width: 50px;
    font-size: 14px;
    padding: 6px 5px;
    right: -5px;
  }
`

const TextBox = styled.div`
  margin-top: 12px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding: 0;
  box-sizing: border-box;
`

const Title = styled.h1`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
`

const Price = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  text-decoration: ${({ onSale }) => (onSale ? "line-through" : "none")};
  margin: 0;
`
const SalePrice = styled.p`
  color: ${colors.sale};
  margin: 0 0 0 5px;
`
const CompanyName = styled.p`
  font-size: 15px;
  margin-bottom: 2px;
`
const Description = styled.p`
  margin: 0;
  font-size: 14px;
  font-family: "PhantomSans";
  line-height: 1.2;
  opacity: 0.8;
`
