import { sluggedName } from "lib/utils"
import { useRouter } from "next/router"
import React from "react"
import styled from "styled-components"
import ProductItem from "./product-item"
import { AnimatePresence } from "framer-motion"

export default function ProductList({ products }) {
  const router = useRouter()
  return (
    <Grid>
      <AnimatePresence>
        {products.map((product, index) => (
          <ProductItem
            key={product.id}
            item={product}
            layout={true}
            index={index}
            onClick={() =>
              router.push(
                `/store/${sluggedName(product.companyName)}?productId=${
                  product.id
                }`
              )
            }
          />
        ))}
      </AnimatePresence>
    </Grid>
  )
}

const Grid = styled.div`
  display: grid;
  grid-gap: 15px;
  row-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  /* max-width: 1200px; */
  margin: 0 auto;

  @media (max-width: 550px) {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
    grid-gap: 10px;
    row-gap: 30px;
  }

  @media (max-width: 360px) {
    grid-template-columns: repeat(2, minmax(130px, 1fr));
    grid-gap: 10px;
    row-gap: 30px;
  }
`
