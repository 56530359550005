export default {
  title: "Unqueue",
  author: {
    name: "Unqueue",
    summary:
      "Unqueue brings the convenience of curbside, in-store, and delivery orders into one platform so you spend a lot less time waiting in line.",
  },
  description:
    "Unqueue brings the convenience of curbside, in-store, and delivery orders into one platform so you spend a lot less time waiting in line.",
  social: {
    twitter: "unqueue_app",
  },
}
