import { Dialog, DialogContent } from "@material-ui/core"
import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import StoreList from "components/search/store-list"
import SearchInput from "components/search-input"
import Spacer from "components/spacer"
import algoliasearch from "algoliasearch/lite"
import { listingData, searchListing } from "../../pages/api/category"
import { IoIosClose } from "react-icons/io"
import {
  InstantSearch,
  connectHits,
  Configure,
  Index,
  connectSearchBox,
} from "react-instantsearch-dom"
import { Flex } from "components/layout/shared"
import ProductList from "components/search/product-list"
import Loader from "react-loader-spinner"
import { colors } from "styles/colors"
import { _filterForOnlineCompanies } from "lib/utils"
import { KOS_COMPANY_ID } from "lib/constants"
import { useRouter } from "next/router"

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY
)

const SearchBox = ({ currentRefinement, refine }) => (
  <SearchInput
    autoFocus
    onChange={event => refine(event.currentTarget.value)}
    placeholder="Enter a search term here"
    value={currentRefinement}
  />
)

const CustomSearchBox = connectSearchBox(SearchBox)

const Hits = connectHits(({ hits }) => {
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    if (hits.length > 0 && !hasLoaded) {
      setHasLoaded(true)
    }
  }, [hits])

  const activeStores = _filterForOnlineCompanies(hits)

  if (!hasLoaded) {
    return (
      <Flex align="center" justify="center" style={{ height: 300 }}>
        <Loader
          type="Oval"
          color={colors.businessPrimary}
          height="40"
          width="40"
        />
      </Flex>
    )
  }

  return (
    <div>
      {
        hits.length ? (
          <StoreContainer>
            <StoreList stores={activeStores} />
          </StoreContainer>
        ) : null
        // <p>There were no results for your search. Please try again.</p>
      }
    </div>
  )
})

export default function SearchModal({ open, setOpen }) {
  const router = useRouter()
  const [data, setData] = useState([])
  // Store the route path from initial load so we can avoiding closing the modal
  // on our initial useEffect
  const [initialRoutePath] = useState(router.asPath)
  const [search, setSearch] = useState("")
  const [hasLoaded, setHasLoaded] = useState(false)

  // Whenever the route changes, close the modal. A change in route means
  // a store was selected.
  useEffect(() => {
    if (router.asPath !== initialRoutePath) {
      handleClose()
    }
  }, [router?.asPath])

  useEffect(() => {
    searchListing(search, {}).then(res => {
      setData(res)
    })
  }, [search])

  const handleClose = () => {
    setOpen(false)
  }

  const onSearchChange = event => {
    setSearch(event.target.value)
  }

  const ProductHits = connectHits(({ hits }) => {
    if (hits.length > 0 && !hasLoaded) {
      setHasLoaded(true)
    }
    // useEffect(() => {
    //   if (hits.length > 0 && !hasLoaded) {
    //     setHasLoaded(true)
    //   }
    // }, [hits])

    if (!hasLoaded) {
      return (
        <Flex align="center" justify="center" style={{ height: 300 }}></Flex>
      )
    }

    const visibleProducts = hits.filter(el => el.visible && el.inStock)
    return (
      <div>
        {/* Always use a ternary when coercing an array length */}
        {/* otherwise you might print out "0" to your UI */}
        <StoreContainer>
          <h2 style={{ fontSize: 30, marginBottom: 20 }}>Products:</h2>
          {hits.length ? <ProductList products={visibleProducts} /> : null}
        </StoreContainer>
      </div>
    )
  })

  return (
    <Dialog fullScreen open={open} onClose={handleClose} maxWidth="md">
      <DialogContent style={{ padding: 0 }}>
        <Container>
          <InstantSearch
            indexName={
              process.env.NODE_ENV === "development"
                ? "dev_COMPANIES"
                : "prod_COMPANIES"
            }
            searchClient={searchClient}
          >
            <HeaderSection>
              <CloseContainer
                style={{ padding: "0 20px" }}
                align="center"
                justify="flex-end"
              >
                <Close>
                  <CloseButton onClick={() => handleClose()}>
                    <IoIosClose size={35} />
                  </CloseButton>
                </Close>
              </CloseContainer>
              <SearchSection>
                <CustomSearchBox />
              </SearchSection>
            </HeaderSection>
            <Grid>
              <Sidebar>
                <Spacer size={20} />
                <Title>Search all stores</Title>
              </Sidebar>
              <div>
                <div>
                  <Index
                    indexName={
                      process.env.NODE_ENV === "development"
                        ? "dev_COMPANIES"
                        : "prod_COMPANIES"
                    }
                  >
                    <Hits />
                  </Index>
                </div>
                <Spacer size={20} />
                <hr />
                <Spacer size={20} />
                <div>
                  <Index
                    indexName={
                      process.env.NODE_ENV === "development"
                        ? "dev_PRODUCTS_V2"
                        : "prod_PRODUCTS_V2"
                    }
                  >
                    <ProductHits />
                  </Index>
                </div>
              </div>
            </Grid>
          </InstantSearch>
        </Container>
      </DialogContent>
    </Dialog>
  )
}

const Container = styled.div`
  background: white;
  padding: 0 0 80px 0;
  position: relative;
`
const CloseContainer = styled(Flex)`
  order: 1;
  @media (max-width: 650px) {
    width: 100%;
    order: 0;
  }
`
const Close = styled.div`
  cursor: pointer;
  height: 40px;
  width: 40px;
  transition: 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 23px;
  :hover {
    background: ${props => (props.black ? "#999" : "#fafafa")};
  }
`
const CloseButton = styled.button`
  background-color: #eee;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  z-index: 2;
  @media (max-width: 650px) {
  }
`
const Grid = styled.section`
  display: grid;
  grid-template-columns: 240px 1fr;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;

  @media (max-width: 650px) {
    grid-template-columns: 100%;
  }
`
const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  max-width: 1200px;
  margin: 0 auto;
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
  padding-top: 20px;

  @media (max-width: 650px) {
    flex-direction: column;
    justify-content: center;
  }
`
const Title = styled.h3`
  font-size: 30px;
  margin-bottom: 15px;
`
const SearchSection = styled(Flex)``
const StoreContainer = styled.div`
  padding: 0 15px;
`
const Sidebar = styled.div`
  padding: 0 15px;
`
const FiltersContainer = styled.div`
  margin: 50px 0 0;
  ul {
    list-style: none;
  }
  li {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.1;
    margin-bottom: 15px;
    cursor: pointer;
  }
`
const FilterHeading = styled.h6`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
`
