import React from 'react'
import styled from 'styled-components';

export default function SearchInput({ autoFocus, disabled, onChange, placeholder, value="" }) {
    return (
        <Container>
            <Input
                autoFocus={autoFocus}
                disabled={disabled}
                onChange={onChange}
                placeholder={placeholder}
                value={value}
            />   
            <i className="fa fa-search"></i>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    border-bottom: 1px solid #eee;
    padding: 15px 0;
`;
const Input = styled.input` 
    border: none;
    outline: none;
    color: black;
    font-family: "PhantomSans";
    font-weight: 500;
    font-size: 18px;
    width: 300px;
    border: 0;
    background: transparent;
    padding-bottom: 0;

    @media (max-width: 550px) {
    }
`;