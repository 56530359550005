import React from "react"

const useProductImage = categoryId => {
  const CATEGORY_MAP = [
    {
      title: "Bakery and Coffee",
      id: "BuEuTyJUHBs0D3yAhuTE",
      image: "/Unqueue Category Tiles_Bakery Tile - Black.png",
    },
    {
      title: "Beauty",
      id: "ytbBtvbjVvImGSRx0rmC",
      image: "/Unqueue Category Tiles_Beauty Tile - black.png",
    },
    {
      title: "Books and Paper Goods",
      id: "un6aoYEAZAatqZ1bYoWp",
      image: "/Unqueue Category Tiles_Book Tile - black.png",
    },
    {
      title: "Clothing and Accessories",
      id: "Q5ThSVUOmYuexpHh8iHM",
      image: "/Unqueue Category Tiles_Clothes Tile - black.png",
    },
    {
      title: "Drinks and Cocktails",
      id: "8n04pzUHeFfHITfIdNh0",
      image: "/Unqueue Category Tiles_Drinks Tile - black.png",
    },
    {
      title: "Electronics",
      id: "ASlMLarMCNSwej3y5ZUU",
      image: "/Unqueue Category Tiles_Electronics Tile - black.png",
    },
    {
      title: "Grocery",
      id: "H9ToSaLhx7liC21nrXnW",
      image: "/Unqueue Category Tiles_Grocery tile - black.png",
    },
    {
      title: "Hardware and Equipment",
      id: "1fYotEQXdsd7V76s2cOf",
      image: "/Unqueue Category Tiles_Hardware tile - black.png",
    },
    {
      title: "Home and Garden",
      id: "r236KBh708XG32BOab6z",
      image: "/Unqueue Category Tiles_Home + Garden tile - black.png",
    },
    {
      title: "Pharmacy and Health",
      id: "76su6do4LkMKa9UIP8h1",
      image: "/Unqueue Category Tiles_Pharmacy tile - black.png",
    },
    {
      title: "Restaurants and Food",
      id: "kO2Wz4uEkFG1LlRNdnfa",
      image: "/Unqueue Category Tiles_Restaurant tile - black.png",
    },
  ]

  const matchedCategory = CATEGORY_MAP.find(
    category => category.id === categoryId
  )

  return (
    matchedCategory?.image ?? "/Unqueue Category Tiles_Book Tile - black.png"
  )
}

export default useProductImage
