import React from "react"

const useHeaderImage = categoryId => {
  const CATEGORY_MAP = [
    {
      title: "Bakery and Coffee",
      id: "BuEuTyJUHBs0D3yAhuTE",
      image: "/header-images/Unqueue_Essential_Category_Headers_Bread.jpg",
    },
    {
      title: "Beauty",
      id: "ytbBtvbjVvImGSRx0rmC",
      image: "/Unqueue Category Headers_Beauty Header - black.png",
    },
    {
      title: "Books and Paper Goods",
      id: "un6aoYEAZAatqZ1bYoWp",
      image: "/Unqueue Category Headers_Books Header - Black.png",
    },
    {
      title: "Clothing and Accessories",
      id: "Q5ThSVUOmYuexpHh8iHM",
      image: "/Unqueue Category Headers_Clothes Header - Black.png",
    },
    {
      title: "Drinks and Cocktails",
      id: "8n04pzUHeFfHITfIdNh0",
      image: "/header-images/Unqueue_Essential_Category_Headers_Drinks.jpg",
    },
    {
      title: "Electronics",
      id: "ASlMLarMCNSwej3y5ZUU",
      image: "/Unqueue Category Headers_Electronics Header - Black.png",
    },
    {
      title: "Grocery",
      id: "H9ToSaLhx7liC21nrXnW",
      image: "/header-images/Unqueue_Essential_Category_Headers_Produce.jpg",
    },
    {
      title: "Hardware and Equipment",
      id: "1fYotEQXdsd7V76s2cOf",
      image:
        "/Unqueue Category Headers_Hardware + Equipment Header - Black.png",
    },
    {
      title: "Home and Garden",
      id: "r236KBh708XG32BOab6z",
      image: "/header-images/Unqueue_Essential_Category_Headers_Plants.jpg",
    },
    {
      title: "Pharmacy and Health",
      id: "76su6do4LkMKa9UIP8h1",
      image: "/header-images/Unqueue_Essential_Category_Headers_Drinks.jpg",
    },
    {
      title: "Restaurants and Food",
      id: "kO2Wz4uEkFG1LlRNdnfa",
      image: "/Unqueue_Category_Headers_Restaurants_Food_Header.png",
    },
  ]

  const matchedCategory = CATEGORY_MAP.find(
    category => category.id === categoryId
  )

  return (
    matchedCategory?.image ??
    "/Unqueue Category Headers_Books Header - Black.png"
  )
}

export default useHeaderImage
