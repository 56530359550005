import algoliasearch from "algoliasearch"
import { sortBy } from "lodash"

// our credential
const recomended = {
  appId: "9I5RF9CHX0",
  apiKey: "04832a88b88e4280738239e154125f33",
  indexName: "filters",
}

// for unqueue
const unqueue = {
  appId: "SM08C8X8QP",
  apiKey: "f6f038283b381616af3dcc4c7781b7b8",
  indexName: "dev_COMPANIES",
}
// abc = position.Latitude;
// console.log('filters.distance ', abc);
let { appId, apiKey, indexName } = unqueue

const client = algoliasearch(appId, apiKey)

const listingData = async () => {
  const index = client.initIndex(indexName)
  await index.search("", {}).then(({ hits }) => {})
}
const searchListing = async (value, filter) => {
  let searchFil = {}
  let temp = { value: false, text: "" }
  if (filter.latest === "latest") {
    temp.value = true
    temp.text = "latest"
  } else if (filter.rating === "rating") {
    temp.value = true
    temp.text = "rating"
  } else if (filter.distance === "distance") {
    temp.value = true
    temp.text = "distance"
  }

  switch (temp.text) {
    case "latest": {
      indexName = "product_latest"
      break
    }
    case "rating": {
      console.log("rating case")
      indexName = "product_rating"
      break
    }
    case "distance": {
      console.log("distance case")

      const getCoords = async () => {
        const pos = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject)
        })
        return {
          long: pos.coords.longitude,
          lat: pos.coords.latitude,
        }
      }
      const coords = await getCoords()
      console.log("coords", coords)
      searchFil["aroundLatLng"] = coords.lat + "," + coords.long
      searchFil["aroundRadius"] = 30000000
      // return await index.search(value,
      //   {
      //     aroundLatLng: coords,
      //     aroundRadius: 30000000 // 30000 km
      //   }
      //   ).then(({hits})=>{
      //     console.log(hits)
      //     return hits
      //   })
      //   .catch(err => { console.log(err) });
      break
    }
    default: {
      indexName = "prod_COMPANIES"
      //statements;
      break
    }
  }
  const index = await client.initIndex(indexName)

  let query = ``
  let exQuery = false
  if (filter.creditSelected) {
    query = `acceptsCard:true`
    exQuery = true
  }

  if (filter.paySelected) {
    if (exQuery) {
      query += ` OR `
    }
    query += `acceptsPayouts:true`
    exQuery = true
  }
  if (filter.cashSelected) {
    if (exQuery) {
      query += ` OR `
    }
    query += `acceptsCash:true`
  }

  if (exQuery) {
    searchFil["filters"] = query
  }
  return await index
    .search(value, {
      filters: query,
    })
    .then(({ hits }) => {
      return hits
    })
    .catch(err => {
      console.log(err)
    })
}
// filters: `rating = ${filter.rating}`
export { listingData, searchListing }
