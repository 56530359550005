import Head from "next/head"
import config from "../config"

export default function SEO({ description, title, image }) {
  const siteTitle = config.title

  return (
    <Head>
      <title>{`${title} | ${siteTitle}`}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={siteTitle} />
      <meta
        property="twitter:card"
        content={image ? "summary_large_image" : "summary"}
      />
      <meta property="twitter:creator" content={config.social.twitter} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta name="twitter:creator" content="@unqueue_app" />
      {image && <meta property="og:image" content={image} />}
      {image && <meta property="twitter:image" content={image} />}
    </Head>
  )
}
